// This SCSS generates the css for the general styles
// To keep things simple, it uses the standard variables of
// bootstrap 4 conventions.

@media all {
  .link-with-icon {
    color: $primary;
    transition: color 0.3s ease-in-out;
    font-weight: 700;
    span {
      display:none;
      @include media-breakpoint-up(lg) {
       display:inline;
      }
    }
   
    svg {
      margin-left:15px;
      transition: margin-left .3s ease-in-out;
      path, g {
        transition: stroke .3s ease-in-out;
        
      }
    }
    &:hover {
      color: $white;
      svg {
        margin-left:30px;
        path, g {
          stroke: $white!important;
          
        }
        
      }
    }
  }
  #systembutton {
    transform:rotate(-90deg);
    position: fixed;
    right: -20px;
    bottom: 20%;
    z-index: 200;
    @include media-breakpoint-up(lg) {
      bottom: 35%;
    }
  
  .btn-system {
    color: $body-color!important;
    position: relative;
    padding-left: 32px!important;
    background: $white!important;
    
    // &:before {
    //   content:"";
    //   display:block;
    //   position: absolute;
    //   left:10px;
    //   top: 50%;
    //   margin-top: -6px;
    //   width:12px;
    //   height:12px;
    //   border-radius:50%;
    //   filter: blur(2px);
    
    // }
    &:hover {
      color: $white!important;
      background: $body-color!important;
    }
    }
  }
  /*Content Tall*/
  .co-img-fit {
    top: 0;
    left: 0;
    height:100%;
    width:100%;
    z-index: -2;
    picture {
      display: flex;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
  }
  .hero {
    position: relative;
    display: flex;
    .hero-img {
      left:0;
      width:100%;
      top:0;
      height:100%;

      img {
        object-fit: cover;
        width:100%;
        height:100%;
      }
    }
    &.tall {
      min-height:100vh;
      
      @include media-breakpoint-up(lg) {
        min-height: 100vh;
      }
    }
    &.small {
      min-height: 55vh;
     
      @include media-breakpoint-up(lg) {
        min-height: 60vh;
      }
    }
    h1,h2,h3 {
    }
  }
 
  .row {
    &.small,
    &.tall {
      z-index: 1;
    }
  }

  /* Linkblock with Image and Images with Caption */
  figure {
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s ease-in-out;

    img {
      transition: transform 0.5s ease, opacity 0.3s ease-in-out;
    }

    figcaption {
      position: absolute;
      z-index: 2;
      left: 0;
      bottom: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 5%, rgba(0, 0, 0, 0.7) 100%);
    }
  }

  a:hover {
    figure {
      &.effect-zoom {
        img {
          transform: scale(1.05);
        }
      }

      &.effect-darken {
        img {
          opacity: 0.8;
        }
      }
    }
  }

  .bg-primary.placeholder {
    padding-bottom: 58%;
    margin-bottom: 1rem;
  }

  .alert p:last-child {
    margin-bottom: 0;
  }

  a {
    /* video link */
    &.co-video {
      display: block;
      position: relative;
      figure {
        z-index: -2;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 0.3));
        height: 54px;
        .st0,
        .st1 {
          transition: fill 0.3s ease-in-out;
        }
      }
    }
    &:hover {
      &.co-video {
        svg {
          .st0 {
            fill: $cyan !important;
            filter: none;
          }
          .st1 {
            fill: #fff !important;
            filter: none;
          }
        }
      }
    }
  }
  .row-grid {
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 30px 30px; 
    grid-template-areas: 
      "co-linkblock-1"
      "co-linkblock-2"
      "co-linkblock-3"; 
      @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 0.75fr 0.75fr; 
        gap: 30px 30px; 
        grid-template-areas: 
          "co-linkblock-1 co-linkblock-1"
          "co-linkblock-2 co-linkblock-3"; 
      }
  

      @include media-breakpoint-up(lg) {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 30px 30px;
        grid-auto-flow: row;
        grid-template-areas:
          "co-linkblock-1 co-linkblock-2"
          "co-linkblock-1 co-linkblock-3";
    
      }
  }

  .co-linkblock {
    color: $body-color;

    a {
      text-decoration: none;
    }

    p {
      color: $body-color;
      @include media-breakpoint-up(md) {
      flex:1;
      }
    }
    span.co-count {
      width: 60px;
      height: 60px;
      font-size: 2rem;
      font-weight: bold;
      position: absolute;
      top: -30px;
    }
    span {
      color: $link-color;
      vertical-align: middle;
      display: inline-block;
      padding: 3px 0;
      transition: color 0.3s ease-in-out;
      font-weight: 700;
      font-size:0.85rem;

      svg {
        transition: margin-left 0.3s ease-in-out;
        margin-left: 0;
        vertical-align: text-bottom;
        margin-bottom: 3px;
        height: 12px;
      }
    }

    &:hover {
      a {
        text-decoration: none;
        color: $body-color;

        figure {
          &.effect-zoom {
            img {
              transform: scale(1.05);
            }
          }

          &.effect-darken {
            background-color: $primary;

            img {
              opacity: 0.8;
            }
          }
        }
      }

      span {
        color: $link-hover-color;
        text-decoration: none;
       

        svg {
          transition: margin-left 0.3s ease-in-out;
          margin-left: 8px;
        }
      }
    }

   
    &.co-linkblock-1,&.co-linkblock-2,&.co-linkblock-3 {
      a {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      figure {
        width: 100%;
      }
    }
    &.co-linkblock-1 {
      grid-area: co-linkblock-1;
    }
    &.co-linkblock-2 {
      grid-area: co-linkblock-2;
    }
    &.co-linkblock-3 {
      grid-area: co-linkblock-3;
    }

    &.co-linkblock-overlay {
      color: $white;

      figure {
        .overlay {
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          background-color: rgba($dark, 0.4);
          transition: background-color 0.3s ease-in-out;
        }
      }

      p,
      h3 {
        color: $white;
      }

      span {
        color: white;
        display: inline-block;
        align-self: flex-start;

        svg {
          margin-left: 0;
          vertical-align: text-bottom;
          margin-bottom: 3px;
          height: 12px;
        }
      }

      &:hover {
        a {
          text-decoration: none;

          span {
            color: $link-hover-color;
            text-decoration: none;
            color: $white;

            svg {
              transition: margin-left 0.3s ease-in-out;
              margin-left: 8px;
            }
          }

          figure {
            &.effect-darken {
              .overlay {
                background-color: rgba($dark, 0.2);
              }

              img {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .co-video-embed {
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: auto;
    }

    .overlay {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      background-color: rgba($dark, 0.4);
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;

      &.hide {
        display: none !important;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 0.3));
        height: 54px;
        .st0,
        .st1 {
          transition: fill 0.3s ease-in-out;
        }
      }
      &:hover {
        svg {
          .st0 {
            fill: $cyan !important;
            filter: none;
          }
          .st1 {
            fill: #fff !important;
            filter: none;
          }
        }
      }
    }
  }
}


