$primary:       #FF1111;
$secondary:     #001D38;

// 2.2 set Body colors
$body-bg:       #222c36;
$body-color:    #FFF;
$white:         #FFF;

$font-family-sans-serif: 'Inktrap', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Helvetica Neue", Arial, "Noto Sans",sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;